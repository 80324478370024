import { If } from '@naamdeo/react-lib/control-flow/If';
import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  BreadcrumbPage,
} from '@naamdeo/ui/components/breadcrumb';
import { Fragment, useMemo } from 'react';
import { Link, UIMatch, useMatches } from 'react-router';
import { Navbar } from '~/components/admin-panel/navbar';
import { RouteHandle } from '~/lib/route-handle';

interface ContentLayoutProps {
  title: string;
  pageIsDirty?: boolean;
  ctaText?: string;
  ctaLoading?: boolean;
  onCtaClick?: () => void;
  children: React.ReactNode;
}

export function ContentLayout({ title, ctaText, pageIsDirty, ctaLoading, onCtaClick, children }: ContentLayoutProps) {
  return (
    <div className="h-full w-full">
      <Navbar
        title={title}
        ctaText={ctaText}
        onCtaClick={onCtaClick}
        ctaLoading={ctaLoading}
        pageIsDirty={pageIsDirty}
      />
      <div className="px-4 pt-8 pb-8 sm:px-8">
        <Breadcrumbs />
        {children}
      </div>
    </div>
  );
}

function Breadcrumbs() {
  const matches = useMatches() as unknown as UIMatch<unknown, RouteHandle>[];
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  const routesWithBreadcrumb = useMemo(() => matches.filter((m) => m.handle?.breadcrumbText), [matches]);
  return (
    <Breadcrumb className="mb-4">
      <BreadcrumbList>
        {routesWithBreadcrumb.map((match, index) => (
          <Fragment key={match.id}>
            <If condition={index < routesWithBreadcrumb.length - 1}>
              <BreadcrumbItem>
                <BreadcrumbLink asChild>
                  <Link to={match.pathname}>{match.handle.breadcrumbText}</Link>
                </BreadcrumbLink>
              </BreadcrumbItem>
              <If condition={index < routesWithBreadcrumb.length - 1}>
                <BreadcrumbSeparator />
              </If>
            </If>
            <If condition={index === routesWithBreadcrumb.length - 1}>
              <BreadcrumbItem>
                <BreadcrumbPage>{match.handle.breadcrumbText}</BreadcrumbPage>
              </BreadcrumbItem>
            </If>
          </Fragment>
        ))}
      </BreadcrumbList>
    </Breadcrumb>
  );
}
